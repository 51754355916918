table {
  width: 100%;
  font-size: 0.9rem;
  border-collapse: collapse;

  margin-top: 1em;
  margin-bottom: 1em;
}

th {
  text-align: left;
}

tr {
  border-bottom: 1px solid var(--gray-color);
}

@media print {
  tr {
    page-break-inside: avoid;
    border-bottom: 0.1rem solid var(--gray-color);
  }
}

td,
th {
  vertical-align: top;
  padding-right: 1rem;

  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

td:last-child,
th:last-child {
  padding-right: 0;
}

table {
  --open-sans-fea-pnum: "pnum" off;
  --open-sans-fea-lnum: "lnum" on;

  font-feature-settings: var(--open-sans-fea-aalt), var(--open-sans-fea-dnom),
    var(--open-sans-fea-frac), var(--open-sans-fea-liga),
    var(--open-sans-fea-lnum), var(--open-sans-fea-locl),
    var(--open-sans-fea-numr), var(--open-sans-fea-onum),
    var(--open-sans-fea-ordn), var(--open-sans-fea-pnum),
    var(--open-sans-fea-salt), var(--open-sans-fea-ss01),
    var(--open-sans-fea-ss02), var(--open-sans-fea-ss03),
    var(--open-sans-fea-subs), var(--open-sans-fea-sups),
    var(--open-sans-fea-tnum), var(--open-sans-fea-zero);
}
