
@media print {

    @page {
        size: A4;
        margin: 5mm 10mm 7mm 25mm;
    }


    .content {
        display: block;
        page-break-before: always;
        counter-increment: page;
        right: 0;
        top: 0;
        width: 100%;
        height: 1.5rem;
        border-bottom: 0.05rem solid black;
        margin-bottom: 3.5rem;

        &:after {
            display: inline-block;
            padding-top: 0.4em;
            content: "Signalwerk GmbH · Stefan Huber · CC BY-SA 4.0";
            float: left;
            white-space: nowrap;
            text-align: left;
            position: absolute;
        }

        &:before {
            display: inline-block;
            padding-top: 0.0em;
            content: counter(page);
            content: " " counter(page);
            float: right;
            white-space: nowrap;
        }
    }

}
