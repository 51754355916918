/* reset css */

/* critical:start */
@import "./css/basic";
@import "./css/html";
@import "./css/body";
@import "./css/p";
@import "./css/hr";
@import "./css/list";
@import "./css/link";
@import "./css/img";
@import "./css/blockquote";
@import "./css/heading";
@import "./css/code";
@import "./css/grid";
@import "./css/semantics";
@import "./css/em";
@import "./css/small";
/* critical:end */

@import "./css/print";
@import "./css/table";
